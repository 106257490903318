import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <div className='footer'>
      <h3>Created by MNJJC</h3>
    </div>
  )
}

export default Footer